<template>
  <div>
    <div
      :style="{ marginLeft: topBarLeftMargin, width: contentAreaWidth }"
      class="
        content-area
        left-0
        mt-14
        w-full
        bg-gradient-to-r
        from-gray-300
        bg-gray-100
        p-1
        flex
        justify-between
        z-20
        fixed
      "
    >
      <ul class="flex items-center ml-1">
        <div class="relative text-gray-600">
          <input
            v-model="searchString"
            type="search"
            name="serch"
            placeholder="Search"
            class="
              bg-gray-100
              h-7
              w-96
              px-4
              pr-10
              rounded-lg
              text-sm
              focus:outline-none
            "
          />
          <button type="submit" class="absolute right-0 top-0 mt-1.5 mr-4">
            <svg
              class="h-4 w-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966; color: #cfb47e"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
              />
            </svg>
          </button>
        </div>
      </ul>
    </div>
  </div>

  <div class="inq-table">
    <div>
      <div class="flex flex-col">
        <!-- There was flex grow here -->
        <div class="grid grid-cols-8 p-5 gap-5">
          <!-- <SearchLoadingOverlay v-if="loading"></SearchLoadingOverlay> -->
          <router-link
            v-for="brand in filteredBrands"
            :key="brand._id"
            :to="`/catalogues/${brand._id}`"
            class="
              bg-gray-200
              text-center
              rounded-2xl
              p-6
              transform
              hover:shadow-xl hover:-translate-y-1 hover:scale-108
              duration-300
              ease-in-out
            "
          >
            <p>
              <svg
                class="mx-auto mb-4"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M11 21H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2v2M19 15v6M16 18h6"
                />
              </svg>
            </p>
            <h1 class="uppercase text-black font-bold text-xs py-1">
              {{ brand.name }}
            </h1>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CataloguesAndBrochures",
  data() {
    return {
      searchString: "",
      newCatalogueModalVisible: false,
    };
  },
  methods: {
    ...mapActions(["getDocumentBrands"]),

    handleAddCustomer(customer) {
      console.log(customer);
      this.addCustomer(customer);
    },
  },
  computed: {
    ...mapGetters(["documentBrands"]),
    filteredBrands() {
      this.searchString;
      const regexp = new RegExp(this.searchString, "ig");

      return this.documentBrands.filter((c) => {
        return regexp.test(c.name);
      });
    },
    sidebarCollapsed: {
      get() {
        return this.$store.state.ui.sidebarCollapsed;
      },
      set(newVal) {
        this.$store.commit("SET_SIDEBAR_COLLAPSE_STATE", newVal);
      },
    },

    topBarLeftMargin() {
      this.sidebarCollapsed;

      if (this.sidebarCollapsed) return "4rem";
      else return "16.5%";
    },
    contentAreaWidth() {
      this.sidebarCollapsed;
      if (this.sidebarCollapsed) return "calc(100% - 4rem)";
      else return "83.5%";
    },
  },
  mounted() {
    this.getDocumentBrands();
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
}

.sidebar {
  width: 16.5%;
}

.content-area {
  transition: margin-left 0.5s ease-in-out;
}
.inq-table {
  margin-top: 5.9rem;
  th {
    position: sticky;
    z-index: 48;
    top: 5.75rem;
  }
}
</style>